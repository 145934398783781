import './darkMode.css';

const THEME_KEY = 'theme';
const THEME_ATTRIBUTE = 'data-theme';

const THEMES = {
  DARK: 'dark',
  LIGHT: 'light',
};

export default class DarkMode {
  constructor() {
    this.toggleSwitches = document.querySelectorAll('.theme-switch input[type="checkbox"]');
  }

  init() {
    if (!this.toggleSwitches.length) {
      return;
    }

    this.toggleSwitches.forEach((toggleSwitch) => {
      toggleSwitch.addEventListener('change', () => {
        const currentTheme = document.documentElement.getAttribute(THEME_ATTRIBUTE);
        const newTheme = currentTheme === THEMES.DARK ? THEMES.LIGHT : THEMES.DARK;
        this.setTheme(newTheme);
      });
    });
  }

  setTheme(theme) {
    document.documentElement.setAttribute(THEME_ATTRIBUTE, theme);
    this.toggleSwitches.forEach((toggleSwitch) => {
      // eslint-disable-next-line no-param-reassign
      toggleSwitch.checked = theme === 'dark';
    });
    const themeModeElement = document.querySelector('[data-theme-mode]');
    if (themeModeElement) themeModeElement.innerText = theme === THEMES.DARK ? 'Light' : 'Dark';
    localStorage.setItem(THEME_KEY, theme);
  }
}
